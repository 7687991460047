import React from "react";
import Head from "next/head";
import { GetStaticProps, NextPage } from "next";
import { NumberToWords as numToWords } from "@app/utilities/numbers";

import avatar from "@assets/images/vadi.jpg";
import { ExperienceComp } from "@app/components/ExperienceComp";
import { ProjectComp } from "@app/components/ProjectComp";
import { getExperiences } from "@app/http/cms/experiences";
import { getProjects } from "@app/http/cms/projects";
import { ExperienceModel } from "@models/ExperienceModel";
import { ProjectModel } from "@models/ProjectModel";
import { APP_URL, CONTACT_EMAIL, META_URL, SOCIAL_CODEPEN, SOCIAL_FACEBOOK, SOCIAL_GITHUB, SOCIAL_IG, SOCIAL_LINKEDIN, SOCIAL_STACKOVERFLOW, SOCIAL_TWITTER } from "@app/utilities/constants";

type IProfile = {
  phrase: string;
  techs: string[];
  experiences: ExperienceModel[];
  projects: ProjectModel[];
};

interface IHomepageProps {
  resumeJson: IProfile;
  isBot: boolean;
}

const WebsiteSchema = {
  "@context": "https://schema.org",
  "@type": "Person",
  name: "Vadi Taslim",
  url: APP_URL,
  image: [APP_URL, "avatar.jpg"].join("/"),
  jobTitle: "Software Engineer",
  email: CONTACT_EMAIL,
  sameAs: [SOCIAL_GITHUB, SOCIAL_CODEPEN, SOCIAL_TWITTER, SOCIAL_STACKOVERFLOW, SOCIAL_FACEBOOK, SOCIAL_LINKEDIN, SOCIAL_IG].filter(Boolean),
};

const Home: NextPage<IHomepageProps> = ({ resumeJson, isBot }: IHomepageProps) => {
  const date = new Date(),
    year = date.getFullYear(),
    startYear = 2011,
    totalYearsExp = year - startYear,
    totalRemoteYearsExp = totalYearsExp - 4,
    totalYearsExpText = numToWords(totalYearsExp, { case: "lowerCase" }),
    totalRemoteYearsExpText = numToWords(totalRemoteYearsExp, {
      case: "lowerCase",
    });

  /**
   * Meta contents
   */
  const metaUrl = META_URL;

  return (
    <div id="home">
      <Head>
        <link rel="canonical" href={metaUrl} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(WebsiteSchema),
          }}
        ></script>
      </Head>
      <div className="container">
        {/* Intro */}
        <div id="intro" className="jumbotron wrapper bg-transparent">
          <p className="small mb-1 text-primary"> Hi, my name is </p>
          <h1 className="font-weight-bold">Vadi Taslim </h1>
          <h2 className="phrase small mb-2 mb-sm-4 font-sfmono">
            {resumeJson.phrase}
            <span className="ninja font-italic m-0 p-0"> And there's a goal to catch </span>
          </h2>

          <div className="row p-0 mb-2 mb-sm-5">
            <div className="col-xs-12 col-sm-8">
              <p>
                I am a passionate software developer based in Bali specialized in engineering web applications with {totalYearsExpText} years experience
                <span className="hide-sm"> inclusive {totalRemoteYearsExpText} years working remotely</span>.
              </p>
              <p>My objective is to contribute to the technology industry while perfecting my ability to contribute even more.</p>
            </div>
            <div className="col-xs-12 col-sm-4 hide-sm"></div>
          </div>

          <a href={"mailto:" + CONTACT_EMAIL} className="btn btn-outline-primary px-4 py-2">
            Get In Touch
          </a>
        </div>

        {/* About Me */}
        <section id="about-me" className="wrapper">
          <div className="row mb-4">
            <div className="col">
              <h3 className="heading font-weight-bold"> About Me </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-8 mb-5 mb-sm-3">
              <p> Hello! I'm Vadi, a software engineer based in Denpasar, ID. </p>
              <p>
                I enjoy discovering new things on the web, experimenting unknown sources with clear goals, being tech-savvy and hopefully as useful resources for other people. My dream is to become a game designer, and hopefully on the
                right track.
              </p>
              <p>
                I graduated from&nbsp;
                <a href="https://www.ait.edu.au/" target="_blank" rel="noreferrer noopener">
                  AIT
                </a>
                &nbsp; with a Game Design degree, and from&nbsp;
                <a href="https://www.sydney.edu.au/" target="_blank" rel="noreferrer noopener">
                  Sydney University
                </a>
                &nbsp; with Interactive Design &amp; Electronic Arts. My programming skills are self-taught when I started joining software engineering in {startYear}, whereafter I have been working on a wide variety of interesting and
                meaningful projects on a daily basis.
              </p>
            </div>
            <div className="col-12 col-sm-4">
              <a href={SOCIAL_GITHUB} target="_blank" className="avatar" rel="noreferrer noopener">
                <img src={avatar.src} className="img-thumbnail" alt="vadi" width={340} height={349} />
              </a>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <h4 className="text-center my-7"> Techs that I work with </h4>
              <ul className="techs">
                {resumeJson.techs.map((tech, idx) => {
                  return <li key={idx}>{tech}</li>;
                })}
              </ul>
            </div>
          </div>
        </section>

        {/* Experiences */}
        <section id="experience" className="wrapper">
          <div className="row mb-4">
            <div className="col">
              <h3 className="heading font-weight-bold"> Where have I been? </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5 mb-sm-3">
              <ExperienceComp data={resumeJson.experiences} asBot={isBot} />
            </div>
          </div>
        </section>

        {/* Projects */}
        <section id="projects" className="wrapper">
          <div className="row mb-4">
            <div className="col">
              <h3 className="heading font-weight-bold"> Some things I've built </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5 mb-sm-3">
              <ProjectComp data={resumeJson.projects} />
            </div>
          </div>
        </section>

        {/* Last */}
        <section id="contact" className="wrapper">
          <div className="row mb-4">
            <div className="col">
              <h4 className="heading no-hr font-blue justify-content-center font-weight-bold"> What's Next? </h4>
              <h3 className="big font-weight-bold text-center"> Get In Touch </h3>
            </div>
          </div>
          <div className="row mb-3 mb-sm-5">
            <div className="d-none d-sm-flex col-sm-2"></div>
            <div className="col-12 col-sm-8 mb-3 mb-sm-5">
              <p className="text-center">
                My inbox is always open! <br /> If you think I can help you with anything or you just want to say hello, feel free to e-mail me and I shall try my best to get back to you!
              </p>
            </div>
            <div className="d-none d-sm-flex col-sm-2"></div>
          </div>
          <div className="row">
            <div className="col text-center">
              <a href={"mailto:" + CONTACT_EMAIL} className="btn btn-outline-primary px-4 py-2">
                Say Hi
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const [experiences, projects] = await Promise.all([getExperiences(), getProjects()]),
    resumeJson = {
      phrase: "Building things for the web.",
      techs: ["HTML & (S)CSS", "JavaScript (ES5+)", "React", "Next.js", "Angular", "Node", "Wordpress", "Docker", "MySQL", "Redis", "Solr", "Java", "jQuery", "PostgreSQL", "PHP"],
      experiences: experiences,
      projects: projects,
    };

  return {
    props: {
      resumeJson,
      isBot: false,
    } as IHomepageProps,
    revalidate: 86400 // In seconds
  };
};

export default Home;

import React, { useState } from "react";
import moment from "moment";
import { ExperienceModel } from "@models/ExperienceModel";
import { TrailList } from "./list/TrailList";

import styles from "@css/components/experience-comp.module.scss";
import classNames from "classnames";

interface IExperienceCompProps {
  data: ExperienceModel[];
  asBot?: boolean;
}

export const ExperienceComp: React.FC<IExperienceCompProps> = ({ data: experienceData, asBot }) => {
  const [activeExperience, setActiveExperience] = useState<ExperienceModel>(experienceData[0]),
    onExperienceIdxChanged = (idx: number) => {
      if (idx >= 0) {
        const experience = experienceData[idx];

        if (experience) {
          setActiveExperience(experience);
        }
      }
    },
    classes = classNames(styles["experience-comp"], {
      [styles["bot"]]: !!asBot,
    });

  if (asBot) {
    /**
     * If it's a bot
     * Show all experiences
     */
    return (
      <div className={classes}>
        {experienceData.map((experience, idx) => {
          const activeStart = moment(experience.startDate).format("MMMM, yy"),
            activeEnd = experience.endDate ? moment(experience.endDate).format("MMMM, yy") : "Present";

          return (
            <div className={styles["experience"]} key={idx}>
              <h4 className={styles["job-name"]}>
                {experience.position}&nbsp;
                <a href={experience.companyUrl} target="_blank" rel="noreferrer noopener">
                  @ {experience.companyName}
                </a>
              </h4>
              <h5 className={styles["details"]}>
                <p>
                  {activeStart} &mdash; {activeEnd}
                </p>
                <p>{experience.companyLocation}</p>
              </h5>
              <div className={styles["description"]} dangerouslySetInnerHTML={{ __html: experience.responsibilities.html }}></div>
            </div>
          );
        })}
      </div>
    );
  } else {
    /**
     * If it's not a bot
     * Show single experience with navigation list.
     */
    const activeStart = moment(activeExperience.startDate).format("MMMM, yy"),
      activeEnd = activeExperience.endDate ? moment(activeExperience.endDate).format("MMMM, yy") : "Present";

    return (
      <div className={classes}>
        <TrailList list={experienceData.map((elem) => elem.companyName)} onActiveIdxChanged={onExperienceIdxChanged} />
        <div className={styles["experience"]}>
          <h4 className={styles["job-name"]}>
            {activeExperience.position}&nbsp;
            <a href={activeExperience.companyUrl} target="_blank" rel="noreferrer noopener">
              @ {activeExperience.companyName}
            </a>
          </h4>
          <h5 className={styles["details"]}>
            <p>
              {activeStart} &mdash; {activeEnd}
            </p>
            <p>{activeExperience.companyLocation}</p>
          </h5>
          <div className={styles["description"]} dangerouslySetInnerHTML={{ __html: activeExperience.responsibilities.html }}></div>
        </div>
      </div>
    );
  }
};

import React from "react";
import { ProjectModel } from "@models/ProjectModel";

interface IProjectCompProps {
  data: ProjectModel[];
}

export const ProjectComp: React.FC<IProjectCompProps> = ({ data: projectData }: IProjectCompProps) => {
  return (
    <div className="project-comp">
      {projectData.map((project, projectIdx) => {
        const firstImage = project?.images[0],
          thumbnail = firstImage?.url,
          reverse = projectIdx % 2 === 1;

        return (
          <div className="row project mx-0 mt-0" key={project.name}>
            <div className={"col-12 d-none d-sm-flex col-sm-5" + (reverse ? " order-sm-1" : "")}>
              <a href={project.url} className="d-block align-self-center project-link" target="_blank" rel="noreferrer noopener">
                <img src={thumbnail} className="img-thumbnail" alt={project.name} loading="lazy" width={firstImage.width} height={firstImage.height} />
              </a>
            </div>
            <div className={"col-12 col-sm-7 py-3 py-sm-0 project-deets " + (reverse ? "order-sm-0" : " text-right")} style={{ backgroundImage: `url(${thumbnail})` }}>
              <div className="d-block d-sm-none overlay"></div>
              <div className="content">
                <h4 className="small"> Featured Project </h4>
                <h5 className="title mb-3">{project.name}</h5>
                <div className="description p-2 mb-3 p-sm-3 mb-sm-4" dangerouslySetInnerHTML={{ __html: project.description.html }}></div>
                <ul className="techs list-inline font-sfmono">
                  {project.technologies.map((tech, techIdx) => {
                    return <li key={techIdx}>{tech}</li>;
                  })}
                </ul>
                <div className={"d-flex links" + (reverse ? "" : " justify-content-end")}>
                  <a href={project.url} className="link" rel="noreferrer noopener">
                    <i className="fa fa-external-link"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

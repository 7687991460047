import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";

export default function useViewport(): [boolean, boolean] {
  const [isDesktop, setDesktop] = useState(false),
    [isMobile, setMobile] = useState(false),
    { width } = useWindowSize();

  useEffect(() => {
    if (width > 992) {
      setDesktop(true);
      setMobile(false);
    } else {
      setDesktop(false);
      setMobile(true);
    }
  }, [width]);

  return [isDesktop, isMobile];
}

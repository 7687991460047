import React, { createRef, CSSProperties, RefObject, useEffect, useRef, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import useViewport from "@app/hooks/useViewport";

import styles from "@css/components/list/trail-list.module.scss";

interface ITrailList {
  className?: string;
  list: string[];
  activeIdx?: number;
  onActiveIdxChanged?: (idx: number) => void;
}

export const TrailList: React.FC<ITrailList> = (props: ITrailList) => {
  const [list, setList] = useState<string[]>(props.list),
    btnContainerRef = useRef<HTMLDivElement>(),
    [isMobile] = useViewport(),
    [btnRefs] = useState<RefObject<HTMLButtonElement>[]>(props.list.map(() => createRef())),
    [activeIdx, setActiveIdx] = useState(props.activeIdx || 0),
    onListClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      const elem = evt.target as HTMLButtonElement,
        idx = _.clamp(elem.dataset.idx, 0, list.length);

      setActiveIdx(idx);
      if (props.onActiveIdxChanged && typeof props.onActiveIdxChanged === "function") {
        props.onActiveIdxChanged(idx);
      }
    };

  useEffect(() => {
    setList(list || []);
  }, [props.list]);

  useEffect(() => {
    if (props.activeIdx >= 0) {
      setActiveIdx(props.activeIdx);
    }
  }, [props.activeIdx]);

  const classes = classNames(styles["trail-list-comp"], props.className),
    [hPlateStyles, setHPlateStyles] = useState<CSSProperties>(),
    [vPlateStyles, setVPlateStyles] = useState<CSSProperties>();

  useEffect(() => {
    const btnContainerRect = btnContainerRef?.current?.getBoundingClientRect(),
      activeBtnRect = btnRefs[activeIdx]?.current?.getBoundingClientRect();

    if (btnContainerRect && activeBtnRect) {
      setHPlateStyles({
        width: activeBtnRect.width || 0,
        height: 2,
        transform: `translateX(${activeBtnRect.left - btnContainerRect.left + btnContainerRef.current.scrollLeft}px)`,
      });

      setVPlateStyles({
        width: 2,
        height: activeBtnRect.height || 0,
        transform: `translateY(${activeBtnRect.top - btnContainerRect.top}px)`,
      });
    }
  }, [btnContainerRef, btnRefs, activeIdx, isMobile]);

  return (
    <div className={classes}>
      <div ref={btnContainerRef} className={styles["list-container"]}>
        {list.map((elem, idx) => {
          const btnClasses = classNames(styles["list-elem"], {
            [styles["active"]]: activeIdx === idx,
          });

          return (
            <button ref={btnRefs[idx]} className={btnClasses} data-idx={idx} onClick={onListClick} key={elem} role="tab">
              {elem}
            </button>
          );
        })}
        <div className={classNames(styles["plate"], styles["horizontal"])} style={hPlateStyles}></div>
        <div className={classNames(styles["plate"], styles["vertical"])} style={vPlateStyles}></div>
      </div>
    </div>
  );
};

const thousands = ["", "Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion"];
const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
const cases = ["titleCase", "lowerCase", "upperCase"];
const caseFunctions = [String.prototype.toString, String.prototype.toLowerCase, String.prototype.toUpperCase];

export function NumberToWords(num, options) {
  options = options || {};

  if (options.separator !== "") {
    options.separator = options.separator || ",";
  }

  if (cases.indexOf(options.case) === -1) {
    options.case = cases[0];
  }

  const caseFunction = caseFunctions[cases.indexOf(options.case)];
  const valueArray = [];

  if (typeof num === "number" || num instanceof Number) {
    num = num.toString();
  }

  if (num === "0") {
    return caseFunction.call("Zero");
  }

  const splittedNumbers = num.match(/.{1,}(?=(...){5}(...)$)|.{1,3}(?=(...){0,5}$)|.{1,3}$/g);

  for (let index = 0; index < splittedNumbers.length; ++index) {
    const splitValues = [];
    const splitNum = splittedNumbers[index];

    if (splitNum.length > 3) {
      splitValues.push(module.exports.convertToText(splitNum));
    } else {
      if (splitNum.length === 3 && ones[splitNum.charAt(0)]) {
        splitValues.push(ones[splitNum.charAt(0)]);
        splitValues.push("Hundred");

        if (ones[splitNum.charAt(1)] || ones[splitNum.charAt(2)]) {
          splitValues.push("And");
        }
      }

      if (splitNum.length >= 2) {
        if (splitNum.substr(-2, 1) === "1") {
          splitValues.push(ones[splitNum.substr(-2, 2)]);
        } else {
          if (tens[splitNum.substr(-2, 1)]) {
            splitValues.push(tens[splitNum.substr(-2, 1)]);
          }

          if (ones[splitNum.substr(-1, 1)]) {
            splitValues.push(ones[splitNum.substr(-1, 1)]);
          }
        }
      } else {
        splitValues.push(ones[splitNum.charAt(0)]);
      }
    }

    if (thousands[splittedNumbers.length - 1 - index] && splitValues.length > 0) {
      splitValues.push(thousands[splittedNumbers.length - 1 - index]);
    }

    if (splitValues.length > 0) {
      valueArray.push(splitValues.join(" "));
    }
  }

  return caseFunction.call(valueArray.join(options.separator + " "));
}
